define("additive-mc/components/amc-campaign/email-campaign/preview", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="amc-email-campaign-preview relative ba b--secondary-10 br1
  
  
      {{not this.isDisabled "cpointer"}}"
    role="button"
    style={{html-safe this._style}}
  >
    <UiActionButton
      @classNames="amc-email-campaign-preview__preview-button"
      @isBlur={{true}}
      @icon="popup"
      @onClick={{this.click}}
      @isPromise={{true}}
      @isDisabled={{this.isDisabled}}
      @label={{t "components.amc-email-campaign-preview.preview"}}
    />
    <div
      class="amc-email-campaign-preview__content w-100 h-100 br1"
      {{did-insert this.setupMutationObserver}}
      {{will-destroy this.destroyMutationObserver}}
    >
      {{yield (hash content=(component "ui-blank-template"))}}
    </div>
  </div>
  */
  {
    "id": "INuDH3X/",
    "block": "[[[10,0],[15,0,[29,[\"amc-email-campaign-preview relative ba b--secondary-10 br1\\n\\n\\n    \",[28,[37,0],[[30,0,[\"isDisabled\"]],\"cpointer\"],null]]]],[14,\"role\",\"button\"],[15,5,[28,[37,1],[[30,0,[\"_style\"]]],null]],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@classNames\",\"@isBlur\",\"@icon\",\"@onClick\",\"@isPromise\",\"@isDisabled\",\"@label\"],[\"amc-email-campaign-preview__preview-button\",true,\"popup\",[30,0,[\"click\"]],true,[30,0,[\"isDisabled\"]],[28,[37,3],[\"components.amc-email-campaign-preview.preview\"],null]]],null],[1,\"\\n  \"],[11,0],[24,0,\"amc-email-campaign-preview__content w-100 h-100 br1\"],[4,[38,4],[[30,0,[\"setupMutationObserver\"]]],null],[4,[38,5],[[30,0,[\"destroyMutationObserver\"]]],null],[12],[1,\"\\n    \"],[18,1,[[28,[37,7],null,[[\"content\"],[[50,\"ui-blank-template\",0,null,null]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&default\"],false,[\"not\",\"html-safe\",\"ui-action-button\",\"t\",\"did-insert\",\"will-destroy\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "additive-mc/components/amc-campaign/email-campaign/preview.hbs",
    "isStrictMode": false
  });
  let AmcCampaignEmailCampaignPreviewComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec2 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec3 = (0, _emberArgTypes.arg)(_propTypes.number), _dec4 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AmcCampaignEmailCampaignPreviewComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fitContent", _descriptor, this);
      _initializerDefineProperty(this, "isDisabled", _descriptor2, this);
      _initializerDefineProperty(this, "maxSize", _descriptor3, this);
      _initializerDefineProperty(this, "_height", _descriptor4, this);
      _initializerDefineProperty(this, "_width", _descriptor5, this);
      _initializerDefineProperty(this, "onClick", _descriptor6, this);
    }
    get _style() {
      return `height: ${this._height}px;width: ${this._width}px;`;
    }
    setContentDimensions(element) {
      const iFrame = element.querySelector('iframe');
      if (!iFrame) {
        this._height = 300;
        this._width = 212;
        return;
      }
      if (iFrame && this.fitContent) {
        const borderWidth = 2;
        iFrame.onload = () => {
          const iFrameHeight = iFrame.contentWindow.document.body.scrollHeight;
          const iFrameWidth = iFrame.contentWindow.document.body.scrollWidth;
          let height;
          let width;
          if (iFrameWidth >= iFrameHeight) {
            width = this.maxSize;
            height = Math.round(iFrameHeight / iFrameWidth * this.maxSize);
          } else {
            height = this.maxSize;
            width = Math.round(iFrameWidth / iFrameHeight * this.maxSize);
          }
          this._height = height + borderWidth;
          this._width = width + borderWidth;
          iFrame.style.height = `${height}px`;
          iFrame.style.width = `${width}px`;
        };
      }
    }
    setupMutationObserver(element) {
      this.setContentDimensions(element);

      // Create a new instance of MutationObserver
      this.mutationObserver = new MutationObserver(() => {
        this.setContentDimensions(element);
      });

      // Start observing the element
      this.mutationObserver.observe(element, {
        childList: true
      });
    }
    destroyMutationObserver() {
      if (this.mutationObserver) {
        this.mutationObserver.disconnect();
      }
    }
    async click() {
      if (this.isDisabled) {
        return;
      }
      await this.onClick();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "fitContent", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isDisabled", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "maxSize", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 300;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_height", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "_width", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "onClick", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupMutationObserver", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupMutationObserver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "destroyMutationObserver", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "destroyMutationObserver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "click", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "click"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcCampaignEmailCampaignPreviewComponent);
});